<template>
  <div class="btc-video-card-box hand">
    <div class="video-item">
      <img :src="item.NewsTitlePic" />
      <img src="@/assets/image/btc-video/play.png" />
    </div>
    <div class="text-item">
      <p style="line-height: 14px; margin-bottom: 5px;">{{ item.NewsTitle }}</p>
      <div class="time">
        <div class="btc-triangle triangle-point-right tc-1" />
        <span>{{ item.NewsDateTime }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BTCVideoCard',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
.btc-video-card-box {
  width: 210px;
  height: 230px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}
.video-item {
  height: 120px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-item > img:first-child {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.video-item > img:last-child {
  position: absolute;
}
.text-item {
  min-width: 140px;
  flex: 1;
  padding: 0 10px;
  font-size: 12px;
  margin-top: 10px;
}

.time {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
</style>
