<template>
  <div>
    <b-pagination
      v-model="pageNum"
      :total-rows="total"
      :per-page="pageSize"
      :limit="limit"
      @change="handleChangePage"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: 'BTCPagination',
  props: {
    total: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      limit: 9
    }
  },
  computed: {
    pageNum: {
      get () {
        return this.page
      },
      set (val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get () {
        return this.size
      },
      set (val) {
        this.$emit('update:size', val)
      }
    }
  },
  mounted () {
  },
  methods: {
    handleChangePage (e) {
      this.pageNum = e
      this.$emit('pagination')
    }
  }
}
</script>

<style scoped>
</style>
