<template>
  <div class="content-box">
    <div class="content-main">
      <div class="header-box">
        <p>必孚视频</p>
        <div>
          <input type="text">
          <img src="@/assets/image/btceyes/search.png" style="width: 25px; height: 25px;">
        </div>
      </div>
      <div class="card-box">
        <btc-video-card-col v-for="(item,index) in list" :key="index" :item="item" :class="{ ml: index % 5 !== 0 }" style="margin-top: 20px;" @click.native="handleVideoCard(index)"/>
      </div>
      <div class="pagination">
        <pagination :total="total" :page.sync="listQuery.PageNum" :size.sync="listQuery.PageSize" @pagination="handleChangePage"/>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/pagination'
import BtcVideoCardCol from '@/components/btc-video-card-col'
import { getLocalStore } from '@/utils/common'

export default {
  name: 'CnBTCEyesVideos',
  components: {
    Pagination,
    BtcVideoCardCol
  },
  data () {
    return {
      list: [],
      allList: [],
      listQuery: {
        PageNum: 1,
        PageSize: 15,
        UserID: getLocalStore.UserID,
        KeyWords: '',
        NewsType: 'beef',
        NewsLanguageCode: 'zh-CN',
        SortOrder: 'descending',
        IsPushOut: false
      },
      total: 0
    }
  },
  mounted () {
    this.getEyesList(true)
  },
  methods: {
    getEyesList (isAll = false) {
      this.$store.dispatch('GetEyes', this.listQuery).then(() => {
        this.total = this.$store.getters.eyesTotal
        this.list = this.$store.getters.eyesRows
        if (isAll) {
          this.getAllEyesList()
        }
      }).catch((err) => {
        console.error(err)
      })
    },
    getAllEyesList () {
      const allQuery = Object.assign({}, this.listQuery)
      allQuery.PageSize = this.total
      this.$store.dispatch('GetEyes', allQuery).then(() => {
        this.allList = this.$store.getters.eyesRows
      }).catch((err) => {
        console.error(err)
      })
    },
    handleChangePage () {
      this.getEyesList()
    },
    handleVideoCard (index) {
      const sliceList = this.allList.slice(0).splice(index + (this.listQuery.PageSize * (this.listQuery.PageNum - 1)), 7)
      this.$router.push({ path: '/cn/BTCEyes', query: { list: sliceList } })
    }
  }
}
</script>

<style scoped>
.content-box {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}

.content-main {
  width: 1248px;
  /* height: 765px; */
  background: #ffffff;
  border-radius: 20px;
  margin: 20px 0;
}
.content-main > .header-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
}
.header-box > p {
  margin: 0;
  font-size: 25px;
  border-left: #678faf 3px solid;
  line-height: 25px;
  padding-left: .4em;
}
.header-box > div {
  display: flex;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #e0e0e0;
  padding: 3px;
}
.header-box > div > input {
  height: 24px;
  outline: none;
  border: none;
  border-radius: 12px 0 0 12px;
  padding-left: 10px;
  background: #f5f5f5;
  font-size: 12px;
}
.header-box > div > img {
  margin-left: 5px;
}
.content-main > .card-box {
  min-height: 750px;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 0 40px;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.ml {
  margin-left: 29px;
}
</style>
